<!--
 * @Author: jiang
 * @Date: 2021-08-04 13:24:38
 * @Description:
-->
<template>
  <el-form
    class="b-form-info"
    ref="form"
    :model="formData"
    :rules="formRules"
    label-width="120px"
  >

    <el-form-item
      label="领导所在部门："
      prop="department_id"
    >
      <div>{{ row.department.name }}</div>
    </el-form-item>

    <el-form-item
      label="领导姓名："
      prop="user_id"
    >
      <div>{{ row.user.name }}</div>
    </el-form-item>

    <el-form-item
      label="时间："
      prop="schedule_date"
    >
      <div>{{ row.schedule_date }}</div>
    </el-form-item>

    <el-form-item
      label="内容："
      prop="content"
    >
      <el-input
        v-if="isEdit"
        v-model="formData.content"
        type="textarea"
        :autosize="{minRows: 3}"
        placeholder="请输入"
      ></el-input>
      <div v-else>{{ row.content }}</div>

    </el-form-item>
    <div
      v-if="row.examine_status === 'not_examine'"
      class="btn-box"
    >
      <el-button
        :loading="loading"
        type="primary"
        @click="onAudit"
      >
        <span v-if="formData.content && formData.content !== row.content">保存并</span>
        通过审核
      </el-button>
      <el-button
        type="warning"
        @click="onEdit"
      >{{ isEdit ? '取消' : '修改内容' }}</el-button>
    </div>
  </el-form>
</template>
<script>
import { updateReport } from '@/api/report'
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      loading: false,
      isEdit: false,
      formData: {
        content: '',
      },
      formRules: {
        content: { required: true, message: '必填项', trigger: 'blur' },
      },
    }
  },
  watch: {
    row: {
      handler() {
        this.formData.content = ''
      },
      deep: true,
    },
  },
  methods: {
    onEdit() {
      if (this.isEdit) {
        this.formData.content = ''
        this.formRules.content = []
        this.isEdit = false
      } else {
        this.formData.content = this.row.content
        this.formRules.content = { required: true, message: '必填项', trigger: 'blur' }
        this.isEdit = true
      }
    },
    onAudit() {
      this.$confirm('确认通过吗？', { type: 'warning', title: '提示' })
        .then(() => {
          this.loading = true
          const params = {
            examine_status: 'pass',
          }
          if (this.formData.content && this.formData.content !== this.row.content) {
            params.content = this.formData.content
          }
          updateReport(this.row.id, params)
            .then(res => {
              this.$message.success('操作成功')
              this.$emit('success')
            })
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-box {
  display: flex;
  justify-content: space-around;

  .el-button {
    width: 140px;
  }
}
</style>
